import tippy from 'tippy.js';

const template = document.querySelector('.tooltip-content');

if (template) {
  tippy('.tooltip', {
    content: template.innerHTML,
    allowHTML: true,
    interactive: true,
    placement: 'top-end',
  });
}
