import Swiper, { Navigation } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

if (document.querySelector('.useful-swiper')) {
  new Swiper('.useful-swiper', {
    modules: [Navigation],
    slidesPerView: 1,
    spaceBetween: 27,

    navigation: {
      prevEl: '.useful-prev',
      nextEl: '.useful-next'
    },

    breakpoints: {
      880: {
        slidesPerView: 3
      },

      576: {
        slidesPerView: 2
      },

      410: {
        slidesPerView: 1
      }
    }
  })
}
