import {totalSum} from "../functions/totalSum";

const elements = {
  rentBlock: document.querySelector('.rent'),
  btnPlus: document?.querySelector('.btn-plus'),
  btnMinus: document?.querySelector('.btn-minus'),
  countInput: document?.querySelector('.parameters__count'),
  currentPrice: document?.querySelector('.numeric')
}

if (elements.rentBlock) {
  const priceFor = elements.currentPrice.textContent;
  elements.btnPlus.addEventListener('click', () => {
    elements.countInput.value = +elements.countInput.value + 1;

    if (elements.countInput.max !== '' && +elements.countInput.value >= +elements.countInput.max) {
      elements.countInput.value = elements.countInput.max;
    }

    elements.currentPrice.textContent = totalSum(priceFor, elements.countInput.value);
  });

  elements.btnMinus.addEventListener('click', () => {
    elements.countInput.value = +elements.countInput.value - 1;

    if (+elements.countInput.value < 1) {
      elements.countInput.value = 1;
    }

    elements.currentPrice.textContent = totalSum(priceFor, elements.countInput.value);
  });

  elements.countInput.addEventListener('input', () => {
    setTimeout(() => {
      if (elements.countInput.max !== '' && +elements.countInput.value >= +elements.countInput.max) {
        elements.countInput.value = elements.countInput.max;
      }

      if (+elements.countInput.value < 1) {
        elements.countInput.value = 1;
      }

      elements.currentPrice.textContent = totalSum(priceFor, elements.countInput.value);
    }, 1000);
  })
}


