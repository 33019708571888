const elements = {
  body: document.querySelector('.page__body'),
  burgerBtn: document.querySelector('.burger'),
  burgerMenu: document.querySelector('.header__nav'),
  burgerCross: document.querySelector('.burger-cross')
}

const classes = {
  burgerOpen: 'burger-open',
  overflow: 'overflow'
}

elements.burgerBtn.addEventListener('click', () => {
  elements.burgerMenu.classList.add(classes.burgerOpen);
  elements.body.classList.add(classes.overflow);
});

elements.burgerCross.addEventListener('click', () => {
  elements.burgerMenu.classList.remove(classes.burgerOpen);
  elements.body.classList.remove(classes.overflow);
});
