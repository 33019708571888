const lists = document.querySelectorAll('.tabs-content');
const buttons = document.querySelectorAll('.tabs-category__button');

const classes = {
  btnActive: 'btn-active',
  visuallyHidden: 'visually-hidden'
}

buttons.forEach(button => {
  button.addEventListener('click', (evt) => {
    buttons.forEach(item => {
      item.classList.remove(classes.btnActive);
    });

    evt.currentTarget.classList.add(classes.btnActive);

    lists.forEach(list => {
      list.classList.add(classes.visuallyHidden);
      if (evt.currentTarget.dataset.path === list.dataset.target) {
        list.classList.remove(classes.visuallyHidden);
      }
    });
  });
});
